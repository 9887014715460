import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import "./Post.css";
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { addPost } from '../../api/post';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { viewProfile } from '../../api/user';

const Post = (props) => {
    const navigate = useNavigate();
    const [content, setContent] = useState("");
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    const [profileData, setProfileData] = useState({});
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
    useEffect(() => {
        viewProfile(userProfile?.mobile).then((data) => {
          setProfileData(data.data);
        })
      }, [])

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedImage = e.target.files[0];
            setImage(selectedImage);
        }
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    var modules = {
        toolbar: [
        ]
    };

    var formats = [
        "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "align", "size",
    ];

    const handleProcedureContentChange = (content) => {
        setContent(content)
    };

    const postAdd = () => {
        let postInfo = {
            "id": uuidv4(),
            "image": image,
            "description": description,
            "created_by": userProfile.mobile
        }
        addPost(postInfo).then((data) => {
            props.setPostShow(false)
            return navigate("/");
        })
    }


    return (
        <Modal show={props.postShow} centered className='userModal'>
            <button className='closeModal btn' onClick={props.handlePostClose}><FontAwesomeIcon icon={faXmark} /></button>
         {profileData ?  <div className='addPost'>
            <h1>Post</h1>
            <div className='inputField mt-3'>
                <div >
                    <p>Post image</p>
                    <input className='form-control' type='file' onChange={handleImageChange} />
                </div>
            </div>
            <div className='inputField mt-3'>
                <label>Details</label>
                <div>
                    <textarea value={description} onChange={handleDescriptionChange} className='form-control' />
                </div>
            </div>
            <button className='btn btn-info' onClick={postAdd}>Save</button>
        </div> : <div className='addPost'><h6>You can't post now</h6></div> }
           
        </Modal>
    )
}

export default Post
