import React, { useState, useEffect } from 'react';
import Header from '../../Component/Header/Header';
import { getHomeArea, getHomeCategory, getHomeCity, getHomeSubCategory, homeData } from '../../api/home';
import Profile from '../../Component/Profile/Profile';
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Container, Form, Row } from 'react-bootstrap';
import PostCard from '../../Component/PostCard/PostCard';
import { getCategoryList } from '../../api/category';
import { getSubCategory } from '../../api/sub-category';
import { getCityList } from '../../api/city';
import { getAreaList } from '../../api/area';
import Post from '../../Component/Post/Post';
import { isAuthenticate } from '../../api/admin';


const Home = () => {
  const [searchInput, setSearchInput] = useState('');
  const [country, setCountry] = useState('');
  const [type, setType] = useState('post');
  const [cardType, setCardType] = useState('');
  const [homeLists, setHomeLists] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [count, setCount] = useState(0);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [postShow, setPostShow] = useState(false);
  const handlePostClose = () => setPostShow(false);
  const handlePostShow = () => setPostShow(true);

  useEffect(() => {
    homeData(type, category, subCategory, city, area).then((data) => {
      setHomeLists(data.results);
      setCardType(data.type);
    })
  }, [type, category, subCategory, city, area, count]);

  useEffect(() => {
    getHomeCategory().then((data) => {
      setCategoryList(data);
    })
    getHomeCity().then((data) => {
      setCityList(data);
    })
  }, []);

  useEffect(() => {
    getHomeSubCategory(category).then((data) => {
      setSubCategoryList(data);
    })
  }, [category]);

  useEffect(() => {
    getHomeArea(city).then((data) => {
      setZoneList(data);
    })
  }, [city]);

  const savedUserProfile = localStorage.getItem("niomit");
  const userProfile = JSON.parse(savedUserProfile);

  let auth= isAuthenticate();

  return (
    <div>
      <Header searchInput={searchInput} setSearchInput={setSearchInput} type={type} setType={setType} />
      <Container>
        <Row className='mt-5 d-flex justify-content-center'>
          <Col md={12}>
          {isAuthenticate() ?  <Col md={12}>
          <div className='d-flex justify-content-center homeFilter mb-4 addPostBtn'>
            <button onClick={handlePostShow}>Add Post</button>
          </div>
        </Col> : ""}
           
            <div className='d-flex justify-content-center homeFilter'>
              <Col md={4}>
                <div>
                  <div className='inputField pe-2'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setCountry(e.target.value)}>
                      <option value="">Country: Global</option>
                      <option value={`bangladesh`}>Bangladesh</option>
                    </Form.Select>
                  </div>
                  {country.length ?
                    <div>
                      <div className='inputField mt-2 pe-2'>
                        <Form.Select aria-label="Default select example" onChange={(e) => setCity(e.target.value)}>
                          <option value="">Select City</option>
                          {cityList.map((city) =>
                            <option value={`${city.id}`}>{city.city}</option>
                          )}
                        </Form.Select>
                      </div>
                      {city.length ? <div className='inputField mt-2 pe-2'>
                        <Form.Select aria-label="Default select example" onChange={(e) => setArea(e.target.value)}>
                          <option value="">Select Area</option>
                          {zoneList?.map((area) =>
                            <option value={`${area.id}`}>{area.area}</option>
                          )}
                        </Form.Select>
                      </div> : ""}
                    </div> : ""}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <div className='inputField pe-2'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setCategory(e.target.value)}>
                      <option value="">{cardType === "post" ? "Post type" : "Profile type"}</option>
                      {categoryList.map((category) =>
                        <option value={`${category.id}`}>{category.category}</option>
                      )}
                    </Form.Select>
                  </div>
                  {category.length ? <div className='inputField mt-2 pe-2'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSubCategory(e.target.value)}>
                      <option value="">{cardType === "post" ? "Post Category" : "Profile Category"}</option>
                      {subCategoryList?.map((subCategory) =>
                        <option value={`${subCategory.id}`}>{subCategory.sub_category}</option>
                      )}
                    </Form.Select>
                  </div> : ""}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <div className='inputField'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setYear(e.target.value)}>
                      <option value="">Select year</option>
                      <option value={`2024`}>2024</option>
                    </Form.Select>
                  </div>
                  {year.length ? <div className='inputField mt-2'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setMonth(e.target.value)}>
                      <option value="">Select month</option>
                      <option value={`jan`}>january</option>
                    </Form.Select>
                  </div> : ""}
                  {month.length ? <div className='inputField mt-2'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setDay(e.target.value)}>
                      <option value="">Select day</option>
                      <option value={`01`}>01</option>
                    </Form.Select>
                  </div> : ""}
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col md={12}>
            <Row>
              {cardType === "post" ? <>{homeLists?.map((data) => <Col md={12}><PostCard card={data} count={count} setCount={setCount} /> </Col>)}</>
                : <>{homeLists?.map((data) => <Col md={12}><Profile data={data} /></Col>)}</>}
            </Row>
          </Col>
        </Row>
      </Container>
      <Post postShow={postShow} setPostShow={setPostShow} handlePostClose={handlePostClose} />
    </div>
  )
}

export default Home
