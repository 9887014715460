import { API } from "../config";
import axios from 'axios';
import { del, get, post, put } from "./base-api";
// add post
export const addPost = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/addPost`, body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error);
        throw error; // Optional: Rethrow the error to handle elsewhere
    }
};

export const viewPost = async (id) => {
    return get(`/viewPost/${id}`);
};

export const singlePost = async (id) => {
    return get(`/singlePost/${id}`);
};
// postLike
export const postLike = async (body = {}) => {
    return post(`/postLike`, body);
};

// postDisLike
export const postDisLike = async (body = {}) => {
    return post(`/postDisLike`, body);
};
// postComment
export const postComment = async (body = {}) => {
    return post(`/postComment`, body);
};
// postCommentList 
export const postCommentList = async (id,page,limit) => {
    return get(`/postCommentList/${id}?page=${page}&limit=${limit}`);
};
// post comment reply
export const postCommentReply = async (body = {}) => {
    return post(`/postCommentReply`, body);
};
// postUpdate
export const postUpdate = async (body = {}) => {
    try {
        const response = await axios.put(`${API}/postUpdate`, body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error adding post:', error);
        throw error; // Optional: Rethrow the error to handle elsewhere
    }
};

export const commentDelete = async (id) => {
    return del(`/commentDelete/${id}`);
};


export const commentReplyDelete = async (id) => {
    return del(`/commentReplyDelete/${id}`);
};

// post delete
export const postDelete = async (id) => {
    return del(`/postDelete/${id}`);
};

// post comment update
export const commentUpdate = async (id,body) => {
    return put(`/commentUpdate/${id}`,body);
};

// post comment reply update
export const commentReplyUpdate = async (id,body) => {
    return put(`/commentReplyUpdate/${id}`,body);
};
