import React, { useState, useEffect } from 'react'
import Header from '../../Component/Header/Header'
import { Container, Row, Col } from 'react-bootstrap';
import { userPostList, viewProfile } from '../../api/user';
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./Account.css";
import { logout } from '../../api/admin';
import { useNavigate } from 'react-router-dom';
import EditProfile from '../../Component/EditProfile/EditProfile';
import Post from '../../Component/Post/Post';
import PostCard from '../../Component/PostCard/PostCard';

const Account = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [postList, setPostList] = useState([]);
  const [profileData, setProfileData] = useState({});
  const savedUserProfile = localStorage.getItem("niomit");
  const userProfile = JSON.parse(savedUserProfile);
  const [count, setCount] = useState(0);
  const [postShow, setPostShow] = useState(false);
  const handlePostClose = () => setPostShow(false);
  const handlePostShow = () => setPostShow(true);

  const [profileShow, setProfileShow] = useState(false);
  const handleProfileClose = () => setProfileShow(false);
  const handleProfileShow = () => setProfileShow(true);

  useEffect(() => {
    viewProfile(userProfile.mobile).then((data) => {
      setProfileData(data.data);
    })
    userPostList().then((data) => {
      setPostList(data)
    })
  }, [])

  const logoutBtn = () => {
    logout();
    return navigate(`/`);
  };

  return (
    <div>
      <Header searchInput={searchInput} setSearchInput={setSearchInput} />
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col md={12}>
            <Col md={12}>
              <div className='d-flex justify-content-center homeFilter mb-4 mt-3 addPostBtn'>
                <button onClick={handlePostShow}>Add Post</button>
              </div>
            </Col>
            <div className="">{profileData ? <div className="profileBtnOption">
              <button className='pe-2' onClick={handleProfileShow}>Edit Profile</button>
              <button className='pe-2'>Profile history</button>
              <button onClick={logoutBtn}>Logout</button>
            </div> : <h1>Your profile is not active</h1>}

              <Row>
                {postList?.map((data) => <Col md={12}><PostCard card={data} count={count} setCount={setCount} /> </Col>)}
              </Row>

            </div>
          </Col>
        </Row>
      </Container>
      <EditProfile profileShow={profileShow} setProfileShow={setProfileShow} handleProfileClose={handleProfileClose} />
      <Post postShow={postShow} setPostShow={setPostShow} handlePostClose={handlePostClose} />
    </div>
  )
}

export default Account